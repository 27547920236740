<template>
  <div class="cal-wrapper">
    <!-- <div class="cal-header">
      <div class="l">考勤日历</div>
      <div class="r">
        <div class="iconfont icon-jiantouleft icon" @click="preMonth"></div>
        <div class="title icon">{{ curYearMonth }}</div>
        <div class="iconfont icon-jiantouright icon" @click="nextMonth"></div>
      </div>
    </div> -->
    <div class="cal-body">
      <div class="weeks">
        <span
          v-for="(dayName, dayIndex) in dayNames"
          :key="dayIndex"
          :style="`width:${100 / dayNames.length}%;color:${dayName.color}`"
        >
          {{ dayName.text }}
        </span>
      </div>
      <div class="dates">
        <div
          v-for="(item, index) in dayList"
          :key="index"
          :class="[
            {
              event: item.status ? item.type : false
            }
          ]"
          @click="onClickDate(item)"
        >
          <p
            class="date-num"
            :style="`color:${item.status ? '#000' : '#b0b3b4'};`"
            :class="{
              today: item.status ? today == item.date : false
            }"
          >
            {{
              new Date(item.date).getTime() == new Date(today).getTime()
                ? "今天"
                : item.date.split("/")[2]
            }}
          </p>
          <p
            class="type"
            :style="{ 'background-color': v.legendColor }"
            v-for="(v, i) in item.schedules"
            :key="i"
          >
            {{ v.employeeName || "" }}
          </p>
          <p
            class="type"
            :style="{
              'background-color':
                v.clockState == 0
                  ? '#a1a1a1'
                  : v.clockState == 1
                  ? '#40c057'
                  : '#ff9900'
            }"
            v-for="(v, i) in item.attendance"
            :key="i"
          >
            {{ v.employeeName || "" }}
          </p>
          <p
            class="type self"
            :style="{
              'background-color': v.status
                ? v.clockState == 0
                  ? '#9ea5ab'
                  : v.clockState == 1
                  ? '#40c057'
                  : '#f9b57d'
                : '#4c6ef5'
            }"
            v-for="(v, i) in item.selfAttendance"
            :key="i"
          >
            {{
              v.status
                ? v.clockState == 0
                  ? "缺勤"
                  : v.clockState == 1
                  ? "正常"
                  : "异常"
                : v.name
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    calendar: {
      type: Object,
      required: true
    },
    events: {
      type: Array,
      required: true
    },
    father: {
      type: String,
      required: true
    }
  },
  watch: {
    events(newV) {
      this.events = newV;
    },
    calendar(newV) {
      this.calendar = newV;
    }
  },
  data() {
    return {
      dayNames: [
        { text: "日", color: "red" },
        { text: "一", color: "#000" },
        { text: "二", color: "#000" },
        { text: "三", color: "#000" },
        { text: "四", color: "#000" },
        { text: "五", color: "#000" },
        { text: "六", color: "#7d95f7" }
      ],
      showUnusual: false,
      tableData: [],
      worknData: [],
      currentDay: "",
      checkDate: ""
    };
  },
  methods: {
    nextMonth() {
      this.$emit("month-changed", "next");
    },
    preMonth() {
      this.$emit("month-changed", "pre");
    },
    bgColor(date) {
      // 1 迟到 #f68787 2 未打卡 #56bff5 3 早退 #edb55d
      let bg =
        date.type == 1
          ? "#f68787"
          : date.type == 2
          ? "#56bff5"
          : date.type == 3
          ? "#edb55d"
          : "#fff";
      return `width:${100 / 7}%;background:${bg};color:#fff;`;
    },
    onClickDate(item) {
      if (item.status) {
        this.$emit("onClickDate", item);
      }
    }
  },

  computed: {
    dayList() {
      let firstDay = new Date(
        this.calendar.params.curYear,
        this.calendar.params.curMonth,
        1
      );
      let dayOfWeek = firstDay.getDay();
      // 根据当前日期计算偏移量 // Calculate the offset based on the current date
      if (this.calendar.options.weekStartOn > dayOfWeek) {
        dayOfWeek = dayOfWeek - this.calendar.options.weekStartOn + 7;
      } else {
        dayOfWeek = dayOfWeek - this.calendar.options.weekStartOn;
      }

      let startDate = new Date(firstDay);
      startDate.setDate(firstDay.getDate() - dayOfWeek);

      let item,
        status,
        tempArr = new Array(),
        tempItem;
      for (let i = 0; i < 42; i++) {
        item = new Date(startDate);
        item.setDate(startDate.getDate() + i);

        status =
          this.calendar.params.curMonth === item.getMonth()
            ? item.getDay() == 0 || item.getDay() == 6
              ? 11
              : 1
            : 0;

        tempItem = {
          date: `${item.getFullYear()}/${item.getMonth() +
            1}/${item.getDate()}`,
          status: status,
          customClass: [],
          schedules: [],
          attendance: [],
          selfAttendance: []
        };
        this.events.map(event => {
          // console.log(event.date)

          if (
            new Date(event.shiftDate).getTime() ==
              new Date(tempItem.date).getTime() &&
            status
          ) {
            tempItem[this.father].push(event);
          }
        });
        if (tempItem[this.father].length > 0) {
          tempItem[this.father] = tempItem[this.father].sort(
            (a, b) => a.sort - b.sort
          );
        }
        tempArr.push(tempItem);
      }
      return tempArr;
    },
    curYearMonth() {
      return `${this.calendar.params.curYear}年${this.calendar.params.curMonth +
        1}月`;
    },
    today() {
      let dateObj = new Date();
      return `${dateObj.getFullYear()}/${dateObj.getMonth() +
        1}/${dateObj.getDate()}`;
    }
  }
};
</script>

<style lang="less" scoped>
.cal-wrapper {
  width: 100%;
  text-align: center;
}

.cal-body {
  width: 100%;

  .weeks {
    width: 100%;
    display: flex;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      height: 40px;

      &.weekend {
        color: red;
      }
    }
  }

  .dates {
    display: flex;
    flex-wrap: wrap;

    > div {
      border-radius: 4px;

      &:active {
        background-color: #e8e8e8;
      }
    }

    > div {
      width: calc(100% / 7);
      min-height: 58px;
      font-size: 14px;
    }

    .today {
      background-color: #ffbb00 !important ;
      border-radius: 2px;
      color: #fff !important;

      > p {
        color: #fff !important;
      }
    }

    .date-num {
      margin-bottom: 4px;
    }

    .type {
      min-height: 18px;
      width: calc(100% - 4px);
      font-size: 12px;
      padding: 0 2px;
      color: #fff;
      margin: 0 auto;
      border-radius: 10px;
      margin-bottom: 4px;
      line-height: 18px;
      text-align: center;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      word-break: break-all;
      display: flex;
      align-items: center;
      justify-content: center;

      &.self {
        border-radius: 2px;
        font-size: 12px;
        // height: 20px;
        width: 40px;
      }

      &.early {
        background-color: #40c057;
      }

      &.noon {
        background-color: #ff9900;
      }

      &.night {
        background-color: #4c6ef5;
      }

      &.normal {
        background-color: #40c057 !important;
      }

      &.abnormal {
        background-color: #ff9900 !important;
      }

      &.absenteeism {
        background-color: #a1a1a1 !important;
      }
    }
  }
}
</style>
