<template>
  <div class="selfAttendance_wrapper">
    <div class="titleBar" v-if="isApp"></div>

    <div class="pageTitle">
      <div class="title_icon" @click="$router.go(-1)">
        <span class="icon-left"></span>
      </div>
      <div class="title_txt">
        <span>考勤信息</span>
      </div>
    </div>

    <div class="cashier_box">
      <div class="cashier_img">
        <img
          class="auto_img"
          :src="stationObj.avatar || require('../../assets/images/yonghu.png')"
          alt=""
        />
      </div>
      <div class="cashier_content">
        <div class="cashier_user">
          <div class="user_name">{{ stationObj.name }}</div>
          <div class="user_phone">{{ stationObj.phone }}</div>
        </div>
        <div class="cashier_account">
          <div class="account">收费账号：{{ stationObj.account }}</div>
          <div class="account_reset" @click="onResetPassword">
            <img src="../../assets/images/重置密码.png" alt="" />
            重置密码
          </div>
        </div>
      </div>
    </div>

    <div class="dateChange_box">
      <img
        src="../../assets/images/左箭头.png"
        alt=""
        @click="currentMonth -= 1"
      />
      {{ requestTime | dateFilter_YY_MM }}
      <img
        src="../../assets/images/右箭头.png"
        @click="currentMonth += 1"
        alt=""
      />
    </div>

    <div class="selfAttendance_date">
      <div class="calendar_border">
        <div class="calendar_box">
          <calendar
            :calendar="calendarOptions"
            :events="events"
            :shifts="shifts"
            :father="'selfAttendance'"
            @onClickDate="onClickDate"
          ></calendar>
        </div>
      </div>
    </div>

    <div
      class="selfAttendance_bottom"
      v-show="dateDetail.length > 0"
      ref="bottomSwiper"
    >
      <template>
        <div v-swiper:newSwiper="newSwiperOption">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in dateDetail"
              :key="index"
            >
              <div class="note">
                <div
                  class="note_item"
                  v-for="(v, i) in item.shiftList"
                  :key="i"
                >
                  {{ v }}
                </div>
                <!-- <div class="note_item">夜班：19:00 ~ 次日07:00</div> -->
              </div>

              <div class="bottom_date">
                <div class="title">
                  <div class="title_text">
                    {{ item.shiftDate | dateFilter_YY_MM_DD }}
                  </div>
                  <div class="title_item">{{ item.name }}</div>
                  <div
                    class="title_item"
                    :style="{
                      'background-color':
                        item.clockState == 0
                          ? '#9ea5ab'
                          : item.clockState == 1
                          ? '#40c057'
                          : '#f9b57d'
                    }"
                    v-if="item.status"
                  >
                    {{
                      item.clockState == 0
                        ? "缺勤"
                        : item.clockState == 1
                        ? "正常"
                        : "异常"
                    }}
                  </div>
                </div>
                <div class="content">
                  <div class="content_top">
                    <div class="goWork">
                      上班打卡时间：
                      <span
                        :class="{
                          abnormal: item.clockState == 3 || item.clockState == 4
                        }"
                        >{{ item.clockInTime || "--:--" }}</span
                      >
                    </div>
                    <div class="post">
                      岗位： {{ item.stationName || "---" }}
                    </div>
                  </div>
                  <div class="afterWork">
                    下班打卡时间：
                    <span
                      :class="{
                        abnormal: item.clockState == 2 || item.clockState == 4
                      }"
                      >{{ item.clockOutTime || "--:--" }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- <div class="swiper_box">
        <div
          class="selfAttendance_item"
          v-for="(item, index) in dateDetail"
          :key="index"
        >
          <div class="note">
            <div class="note_item">早班：07:00 ~ 19:00</div>
            <div class="note_item">夜班：19:00 ~ 次日07:00</div>
          </div>

          <div class="bottom_date">
            <div class="title">{{ item.shiftDate }}</div>
            <div class="content">
              <div class="goWork">
                上班打卡时间：
                {{ item.clockInTime || "--:--" }}
              </div>
              <div class="post">岗位： {{ item.stationName || "---" }}</div>
              <div class="afterWork">
                下班打卡时间：
                {{ item.clockOutTime || "--:--" }}
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { Dialog } from "vant";
import { mapState } from "vuex";
import calendar from "../../components/Calendar";
export default {
  components: {
    calendar
  },
  data: () => ({
    stationObj: {},
    currentMonth: new Date().getMonth(),
    events: [],
    shifts: 2,

    dateDetail: [],

    newSwiperOption: {}
  }),

  methods: {
    onClickDate(date) {
      console.log("data == > ", date);

      this.dateDetail = date.selfAttendance;
    },

    onResetPassword() {
      Dialog.confirm({
        title: "系统提示",
        message: "确认重置密码"
      }).then(() => {
        // this.$get(this.$config.TL_TEAM_INFO_RESET_PASSWORD, {
        //   employeeId: this.stationObj.id
        // })
        this.$api.resetPassword({ employeeId: this.stationObj.id }).then(() => {
          Dialog.alert({
            title: "系统提示",
            message: "重置密码成功！"
          });
        });
      });
    },

    getData() {
      // this.$get(this.$config.TL_TEAM_INFO_GET_TOLL_MAN_ATTENDANCE_INFO, {
      //   employeeId: this.stationObj.id,
      //   time: this.requestTime,
      // })
      this.$api
        .getTollmanAttendanceInfo({
          employeeId: this.stationObj.id,
          time: this.requestTime
        })
        .then(res => {
          console.log("res ==> ", res);
          this.events = res["data"]["data"];
        });
    }
  },

  watch: {
    currentMonth() {
      this.getData();
    }
  },

  computed: {
    ...mapState({
      isApp: state => state.isApp
    }),

    calendarOptions() {
      let dateObj = new Date(new Date().getFullYear(), this.currentMonth, 1);
      return {
        options: {
          weekStartOn: 0
        },
        params: {
          curYear: dateObj.getFullYear(),
          curMonth: dateObj.getMonth(),
          curDate: dateObj.getDate(),
          curEventsDate: "all"
        }
      };
    },

    requestTime() {
      let date = new Date(new Date().getFullYear(), this.currentMonth, 1);
      console.log(date.getMonth());

      return `${date.getFullYear()}-${
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1
      }-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`;
    }
  },
  created() {
    this.stationObj = this.$route.query;

    console.log(this.stationObj);

    this.getData();

    this.$tools.entering();
  }
};
</script>

<style lang="less" scoped>
.selfAttendance_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.cashier_box {
  padding: 16px;
  border-bottom: 1px solid rgba(7, 17, 27, 0.1);
  display: flex;

  .cashier_img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #f1f3f5;
    margin-right: 20px;
  }

  .cashier_content {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex: 1;

    .cashier_user {
      display: flex;
      align-items: center;

      .user_name {
        margin-right: 20px;
        font-size: 16px;
        font-weight: 600;
      }
    }

    .cashier_account {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .account_reset {
        display: flex;
        align-items: center;
        padding: 4px 12px;
        background-color: #e9ecef;
        border-radius: 20px;

        > img {
          width: 18px;
          height: auto;
          margin-right: 4px;
        }
      }
    }
  }
}

.dateChange_box {
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    width: 20px;
    height: auto;
    margin: 0 6px;
  }
}

.selfAttendance_date {
  max-height: 380px;
  width: 100%;
  flex: 1;
  padding: 0 4px;
  overflow: hidden;

  .calendar_border {
    width: 100%;
    height: 100%;
    border: 1px solid rgba(7, 17, 27, 0.1);
    border-radius: 10px;

    overflow: hidden;
  }

  .calendar_box {
    overflow: scroll;

    width: 104%;
    padding: 0 2%;
    transform: translateX(-2%);
    height: 100%;
  }
}

.note {
  color: #aab0b6;
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  height: 30px;
  padding: 0 14px;

  .note_item {
    margin-left: 8px;

    // &:last-child {
    //   margin: 0;
    // }
  }
}

.bottom_date {
  padding: 14px;
  color: #333;

  .title {
    margin-bottom: 14px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;

    .title_item {
      color: #fff;
      padding: 2px 8px;
      background-color: #4c6ef5;
      border-radius: 2px;
      font-weight: 400;
      font-size: 14px;
      margin-left: 10px;
    }
  }

  .content {
    font-size: 16px;

    .content_top {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .abnormal {
      color: red;
    }

    > div {
      margin-bottom: 14px;

      &:last-child {
        margin: 0;
      }
    }
  }
}

.swiper_box {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

.selfAttendance_bottom {
  width: 100vw;
  overflow: hidden;
}

.swiper-slide {
  width: 100vw;
}
</style>
